import { useEffect } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useStore } from 'src/hooks';

const listener = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = '';
  return '';
};

export const useUnplannedEvent = () => {
  const { activeStep, unplannedEvent } = useStore(
    useShallow(state => ({
      activeStep: state.activeStep,
      unplannedEvent: state.unplannedEvent
    }))
  );

  useEffect(() => {
    if (activeStep > 0 && activeStep < 4) {
      window.addEventListener('beforeunload', listener);
    }

    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [activeStep]);

  useEffect(() => {
    if (unplannedEvent) {
      window.removeEventListener('beforeunload', listener);
    }
  }, [unplannedEvent]);
};

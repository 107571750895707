import { useState, useCallback } from 'react';

import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import { useRequestsHandler } from './useRequestsHandler';

export type Prices = {
  anotherBirthdayPersonPrice: number;
  participantPrice: number;
  participantWeekendPrice: number;
  tablePrice: number;
  animatorPrice: number;
  supportPrice: number;
  themePrice: number;
  themeOverPrice: number;
  smallCakePrice: number;
  mediumCakePrice: number;
  largeCakePrice: number;
  wafferPrice: number;
  cakeFountainPrice: number;
  ownCakePrice: number;
  kidChampagnePrice: number;
  pizzaMargheritaPrice: number;
  pizzaMargheritaHamPrice: number;
  friesPrice: number;
  nuggetsPrice: number;
  fruitsPrice: number;
  iceCreamPrice: number;
  balloonPrice: number;
  balloonWithNumberPrice: number;
  piniataPrice: number;
  piniataOverPrice: number;
};

type UsePricesStore = {
  prices: Prices;
  setPrices: (prices: Prices) => void;
  pricingId: string | null;
  setPricingId: (id: string) => void;
  pricingDate: null | Date;
  setPricingDate: (date: Date) => void;
};

export const usePricesStore = create<UsePricesStore>(set => ({
  prices: {
    anotherBirthdayPersonPrice: 50,
    participantPrice: 38,
    participantWeekendPrice: 46,
    tablePrice: 15,
    animatorPrice: 120,
    supportPrice: 190,
    themePrice: 80,
    themeOverPrice: 9,
    smallCakePrice: 45,
    mediumCakePrice: 75,
    largeCakePrice: 95,
    wafferPrice: 12,
    cakeFountainPrice: 5,
    ownCakePrice: 30,
    kidChampagnePrice: 14,
    pizzaMargheritaPrice: 25,
    pizzaMargheritaHamPrice: 29,
    friesPrice: 9,
    nuggetsPrice: 10,
    fruitsPrice: 30,
    iceCreamPrice: 5,
    balloonPrice: 26,
    balloonWithNumberPrice: 38,
    piniataPrice: 99,
    piniataOverPrice: 3
  },
  pricingId: null,
  pricingDate: null,
  setPrices: prices => set({ prices }),
  setPricingId: pricingId => set({ pricingId }),
  setPricingDate: pricingDate => set({ pricingDate })
}));

export const useFetchPrices = (): {
  loading: boolean;
  error: boolean;
  fetchPrices: (id?: string) => Promise<void>;
  setError: (error: boolean) => void;
} => {
  const { getRequest } = useRequestsHandler();
  const { setPrices, setPricingId, setPricingDate } = usePricesStore(
    useShallow(state => ({
      setPrices: state.setPrices,
      setPricingDate: state.setPricingDate,
      setPricingId: state.setPricingId
    }))
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchPrices = useCallback(
    async (pricingId?: string) => {
      try {
        setLoading(true);
        setError(false);

        const response = await getRequest<
          Prices & { id: string; createdAt: string }
        >(pricingId ? 'pricingId' : 'pricing', pricingId);
        const { id, createdAt, ...prices } = response.data;
        if (!id || !prices) {
          setError(true);
          return;
        }

        setPricingId(id.toString());
        setPricingDate(new Date(createdAt));
        setPrices(prices);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [getRequest, setPrices, setPricingDate, setPricingId]
  );

  return { loading, error, fetchPrices, setError };
};

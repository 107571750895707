import type { ReactNode } from 'react';

import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';

import { FlexBox } from 'src/components/FlexBox';
import { Navbar } from 'src/components/Navbar';
import { routes } from 'src/helpers';

type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();
  const isIframePage = location.pathname === routes.calendar;
  const isWidePage =
    location.pathname === routes.forms ||
    location.pathname === routes.expired ||
    location.pathname === routes.deleted;

  return (
    <Box>
      <Navbar />
      <Box sx={{ px: isIframePage ? 0 : 2 }}>
        <FlexBox
          maxWidth={isIframePage ? undefined : isWidePage ? 1600 : 1000}
          m={isIframePage ? 0 : '16px auto'}
        >
          {children}
        </FlexBox>
      </Box>
    </Box>
  );
};

export const routes = {
  home: '/',
  calendar: '/kalendarz',
  form: '/formularz',
  terms: '/regulamin',
  privacy: '/polityka-prywatnosci',
  admin: '/admin',
  forms: '/formularze',
  formId: '/formularz/:id',
  edit: '/edycja/:id',
  expired: '/przedawnione',
  deleted: '/usuniete',
  prices: '/cennik',
  contact: '/kontakt',
  rest: '*'
};

import { BalloonWithNumber, getCakeSizeOptions } from 'src/schemas';

export * from './routes';

export const getSummaryPrice = (price: number | null) =>
  price === null ? 'WLICZONO' : `${price.toFixed(2)} zł`;

export const getDocPrice = (price: number | null, internal = false) => {
  const currency = internal ? 'PLN' : 'zł';

  return `${price === null ? 0 : price} ${currency}`;
};

export const formatFormPrice = (price: number) => `${price.toFixed(2)} zł`;

export const formatBalloonNumbers = (ballonWithNumber: BalloonWithNumber[]) => {
  if (!ballonWithNumber.length) {
    return '';
  }

  const formatedNumbers = ballonWithNumber.map(({ number }, index) => {
    if (index === ballonWithNumber.length - 1) {
      return `${number ?? 0}`;
    }

    return `${number ?? 0}, `;
  });

  return formatedNumbers.concat('');
};
export const getCakeSizeLabel = (
  size: string,
  internal = false,
  prices: {
    smallCakePrice: number;
    mediumCakePrice: number;
    largeCakePrice: number;
  }
) =>
  getCakeSizeOptions(
    prices.smallCakePrice,
    prices.mediumCakePrice,
    prices.largeCakePrice
  ).find(option => option.id === size)?.[
    internal ? 'labelInternal' : 'label'
  ] ?? 'Nie wybrano';

export const formatPlainDate = (date: Date | null) =>
  date instanceof Date ? new Date(date.setHours(2, 0, 0, 0)) : date;

export const blobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const getConstantHolidays = (currentYear: number): string[] => {
  const dates: [number, number][] = [
    [0, 1],
    [0, 6],
    [4, 1],
    [4, 3],
    [7, 15],
    [10, 1],
    [10, 11],
    [11, 25],
    [11, 26]
  ];

  dates.forEach(date => date.unshift(currentYear));

  return dates.map(date => new Date(...date).toDateString());
};

const variableHolidays: Record<number, [number, number, number][]> = {
  2023: [
    [2023, 3, 9],
    [2023, 3, 10],
    [2023, 4, 28],
    [2023, 5, 8]
  ],
  2024: [
    [2024, 2, 31],
    [2024, 3, 1],
    [2024, 4, 19],
    [2024, 4, 30]
  ],
  2025: [
    [2025, 3, 20],
    [2025, 3, 21],
    [2025, 5, 8],
    [2025, 5, 19]
  ]
};

const getVariableHolidays = (currentYear: number): string[] => {
  const dates = variableHolidays[currentYear];

  return dates.map(date => new Date(...date).toDateString());
};

const weekendDays = [6, 0];

export const checkIsWeekendOrHoliday = (date: Date) => {
  const dateDay = date.getDay();
  const isWeekend = weekendDays.includes(dateDay);

  if (isWeekend) {
    return true;
  }

  const year = date.getFullYear();

  const constantHolidaysDates = getConstantHolidays(year);
  const variableHolidaysDates = getVariableHolidays(year);

  const holidays = [...constantHolidaysDates, ...variableHolidaysDates];

  return holidays.includes(date.toDateString());
};

import { useEffect } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { FlexBox } from 'src/components/FlexBox';
import { useStore } from 'src/hooks';

type NotFoundProps = {
  error?: boolean;
};

export const NotFound = ({ error }: NotFoundProps) => {
  const { resetSteps, setUnplannedEvent } = useStore(
    useShallow(state => ({
      resetSteps: state.resetSteps,
      setUnplannedEvent: state.setUnplannedEvent
    }))
  );

  useEffect(() => {
    setUnplannedEvent(true);

    return () => {
      setUnplannedEvent(false);
    };
  }, [setUnplannedEvent]);

  const onClick = () => {
    resetSteps();
    if (error) {
      window.location.reload();
    }
  };

  const errorText = error
    ? 'Ups. Coś poszło nie tak'
    : 'Wyszukiwana podstrona nie istnieje';

  return (
    <FlexBox justify="center" align="center" mt={4} width="100%">
      <Typography variant="h4" sx={{ textAlign: 'center' }} width={[300, 600]}>
        {`${errorText}. Wróć na stronę główną przy pomocy poniższego przycisku.`}
      </Typography>
      <Link to="/">
        <Button onClick={onClick} variant="contained" sx={{ mt: 2 }}>
          <Typography variant="h6">Wróć</Typography>
        </Button>
      </Link>
    </FlexBox>
  );
};

import { useCallback, useMemo } from 'react';

import axios, { AxiosResponse } from 'axios';
import { useShallow } from 'zustand/react/shallow';

import { useAuth } from 'src/hooks';

const URL_BASE = process.env.REACT_APP_SERVER_URL_BASE;

const endpoints = {
  token: 'token',
  forms: 'forms',
  formsId: 'forms/',
  formsExpired: 'forms/expired',
  formsDeleted: 'forms/deleted',
  formsToggleDeleteId: 'forms/toggledelete/',
  formsToggleReadId: 'forms/toggleread/',
  validateRecaptcha: 'validate_recaptcha',
  validateCalendesk: 'validate_calendesk',
  deleteInvalid: 'delete_invalid',
  pricing: 'pricing',
  pricingId: 'pricing/'
};

type Endpoints = keyof typeof endpoints;

const getRoute = (endpoint: keyof typeof endpoints, id?: string) => {
  if (!endpoints[endpoint]) {
    throw new Error(`${endpoint} is not a valid endpoint`);
  }
  return `${endpoints[endpoint]}${id || ''}`;
};

export const handleTokenRequest = async (
  username: string,
  password: string
  // eslint-disable-next-line camelcase
): Promise<{ data: { access_token: string } }> => {
  const data = new URLSearchParams();
  data.append('username', username);
  data.append('password', password);

  // eslint-disable-next-line camelcase
  return await axios.post<{ access_token: string }>(
    `${URL_BASE}${getRoute('token')}`,
    data,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      timeout: 5000
    }
  );
};

export const useRequestsHandler = () => {
  const { token } = useAuth(
    useShallow(state => ({
      token: state.token
    }))
  );

  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: URL_BASE,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token || ''}`
        },
        timeout: 5000
      }),
    [token]
  );

  const getRequest = useCallback(
    async <T>(
      endpoint: Endpoints,
      id?: string,
      params: Record<string, unknown> = {}
    ): Promise<{ data: T }> =>
      await axiosInstance.get<T>(getRoute(endpoint, id), { params }),
    [axiosInstance]
  );

  const postRequest = useCallback(
    async <T = unknown>(
      endpoint: Endpoints,
      data: unknown
    ): Promise<AxiosResponse<T, unknown>> =>
      await axiosInstance.post(getRoute(endpoint), data),
    [axiosInstance]
  );

  const putRequest = useCallback(
    async (
      endpoint: Endpoints,
      data: unknown,
      id?: string
    ): Promise<AxiosResponse<unknown, unknown>> =>
      await axiosInstance.put(getRoute(endpoint, id), data),
    [axiosInstance]
  );

  const deleteRequest = useCallback(
    async (
      endpoint: Endpoints,
      id: string
    ): Promise<AxiosResponse<unknown, unknown>> =>
      await axiosInstance.delete(getRoute(endpoint, id)),
    [axiosInstance]
  );

  return { getRequest, postRequest, putRequest, deleteRequest };
};

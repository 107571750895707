import * as yup from 'yup';

export type ContactFormData = {
  firstname: string;
  lastname: string;
  message: string;
  phoneNumber: string;
  email: string;
  privacyPolicy: boolean;
};

export const contactSchema = yup.object<
  Record<keyof ContactFormData, yup.AnySchema>
>({
  firstname: yup.string().required('Imię jest wymagane'),
  lastname: yup.string().required('Nazwisko jest wymagane'),
  message: yup.string().required('Wiadomość jest wymagana'),
  email: yup
    .string()
    .email('Wpisz poprawy adres email')
    .required('Email jest wymagany'),
  phoneNumber: yup
    .string()
    .min(15, 'Numer jest nieprawidłowy')
    .required('Numer telefonu jest wymagany'),
  privacyPolicy: yup
    .boolean()
    .oneOf([true], 'Zaakceptowanie polityki prywatności jest wymagane')
});

export const contactFormInitialValues: ContactFormData = {
  firstname: '',
  lastname: '',
  message: '',
  email: '',
  phoneNumber: '',
  privacyPolicy: false
};

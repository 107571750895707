import { differenceInDays } from 'date-fns';
import { create } from 'zustand';

import {
  participantsInitialValues,
  ParticipantsFormData,
  personalFormInitialValues,
  PersonalFormData,
  CustomPersonalFormData,
  foodFormInitialValues,
  FoodFormData,
  CustomParticipantsFormData,
  invoiceFormInitialValues,
  InvoiceFormData
} from 'src/schemas';

const numberOfDaysForLastMinute = 12;

type UseStore = {
  steps: string[];
  totalSteps: () => number;
  activeStep: number;
  handlePrevStep: () => void;
  handleNextStep: () => void;
  resetSteps: () => void;
  id: string;
  submitDate: Date | null;
  editDate: Date | null;
  personalForm: PersonalFormData;
  setPersonalFormValues: (values: PersonalFormData) => void;
  participantsForm: ParticipantsFormData;
  setParticipantsFormValues: (values: ParticipantsFormData) => void;
  foodForm: FoodFormData;
  setFoodFormValues: (values: FoodFormData) => void;
  invoiceForm: InvoiceFormData;
  setInvoiceFormValues: (values: InvoiceFormData) => void;
  isInvoiceData: () => boolean;
  setAllFormValues: (
    id: string,
    personalFormValues: CustomPersonalFormData,
    participantsFormValues: CustomParticipantsFormData,
    foodFormValues: FoodFormData,
    invoiceFormValues: InvoiceFormData | null,
    submitDate: string,
    edit: boolean,
    editDate: string | null
  ) => void;
  clearAllFormValues: () => void;
  lastMinuteSuspended: boolean;
  toggleLastMinuteSuspended: () => void;
  unplannedEvent: boolean;
  setUnplannedEvent: (isEvent: boolean) => void;
};

export const useStore = create<UseStore>((set, get) => ({
  steps: ['Dane rezerwacji', 'Szczegóły urodzin', 'Dodatki', 'Podsumowanie'],
  totalSteps: () => get().steps.length,
  activeStep: 0,
  handlePrevStep: () => set(state => ({ activeStep: state.activeStep - 1 })),
  handleNextStep: () => set(state => ({ activeStep: state.activeStep + 1 })),
  resetSteps: () =>
    set({
      activeStep: 0,
      personalForm: personalFormInitialValues,
      participantsForm: participantsInitialValues,
      foodForm: foodFormInitialValues
    }),

  id: '',
  submitDate: null,
  editDate: null,

  personalForm: personalFormInitialValues,
  setPersonalFormValues: personalFormValues =>
    set(state => {
      state.handleNextStep();
      return {
        personalForm: {
          ...personalFormValues,
          lastMinute: state.id
            ? state.personalForm.lastMinute
            : differenceInDays(personalFormValues.date as Date, new Date()) <
              numberOfDaysForLastMinute
        }
      };
    }),

  participantsForm: participantsInitialValues,
  setParticipantsFormValues: participantsFormValues =>
    set(state => {
      state.handleNextStep();
      return {
        participantsForm: participantsFormValues
      };
    }),

  foodForm: foodFormInitialValues,
  setFoodFormValues: foodFormValues =>
    set(state => {
      state.handleNextStep();
      return {
        foodForm: foodFormValues
      };
    }),

  invoiceForm: invoiceFormInitialValues,
  setInvoiceFormValues: invoiceFormValues =>
    set({
      invoiceForm: invoiceFormValues
    }),
  isInvoiceData: () => Object.values(get().invoiceForm).some(Boolean),

  setAllFormValues: (
    id,
    personalFormValues,
    participantsFormValues,
    foodFormValues,
    invoiceFormValues,
    submitDate,
    edit,
    editDate
  ) =>
    set({
      activeStep: edit ? 0 : -1,
      personalForm: {
        ...personalFormValues,
        date: new Date(personalFormValues.date)
      },
      participantsForm: {
        ...participantsFormValues,
        tableNumber: participantsFormValues.tableNumber || undefined,
        themeOption: participantsFormValues.themeOption
          ? [participantsFormValues.themeOption]
          : []
      },
      foodForm: {
        ...foodFormValues,
        cake: foodFormValues.cake.map(singleCake => ({
          ...singleCake,
          candleNumber: singleCake.candleNumber ?? ''
        }))
      },
      invoiceForm: invoiceFormValues ?? invoiceFormInitialValues,
      id,
      submitDate: new Date(submitDate),
      editDate: editDate ? new Date(editDate) : null
    }),
  clearAllFormValues: () =>
    set({
      activeStep: 0,
      lastMinuteSuspended: false,
      personalForm: personalFormInitialValues,
      participantsForm: participantsInitialValues,
      foodForm: foodFormInitialValues,
      invoiceForm: invoiceFormInitialValues,
      id: '',
      submitDate: null,
      editDate: null
    }),
  lastMinuteSuspended: false,
  toggleLastMinuteSuspended: () =>
    set(state => ({ lastMinuteSuspended: !state.lastMinuteSuspended })),
  unplannedEvent: false,
  setUnplannedEvent: isEvent => set(() => ({ unplannedEvent: isEvent }))
}));

export const styles = {
  container: { flexGrow: 1, background: 'blue' },
  toolbar: { display: 'flex', justifyContent: 'space-between' },
  menuIcon: { mr: 2, display: ['block', 'block', 'none'] },
  menu: { display: ['block', 'block', 'none'] },
  menuItemText: { flexGrow: 1 },
  menuLinkItem: {
    flexGrow: 1,
    display: ['none', 'none', 'block'],
    height: 64,
    '&:hover': {
      backgroundColor: '#3a91e8'
    },
    paddingLeft: 1,
    paddingRight: 1
  },
  menuLink: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center'
  },
  menuLinkItemText: {
    flexGrow: 1,
    ml: 2,
    display: ['none', 'none', 'block'],
    cursor: 'pointer'
  },
  superAdminMenu: {
    display: 'block',
    color: '#fff',
    textTransform: 'none',
    height: 64,
    '&:hover': {
      backgroundColor: '#3a91e8'
    }
  },
  moreText: { flexGrow: 1 },
  logoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  heading: { flexGrow: 1, mr: 1 },
  logo: { width: 64, padding: 4, marginRight: 8 },
  logoLink: {
    display: 'flex',
    alignItems: 'center'
  }
};

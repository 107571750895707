import format from 'date-fns/format';

import { placeNames } from './useFieldsData';
import { useRequestsHandler } from './useRequestsHandler';

import { Place } from '../schemas';

type CalendeskValidationResponse = {
  success: boolean;
  error: boolean;
};

export const useValidateCalendeskRequest = () => {
  const { postRequest } = useRequestsHandler();

  const validateCalendeskBooking = async ({
    date,
    time,
    place,
    email,
    phoneNumber
  }: {
    date: Date | null;
    time: string;
    place: Place;
    email: string;
    phoneNumber: string;
  }): Promise<CalendeskValidationResponse> => {
    const formattedDate = format(date as Date, 'yyyy-MM-dd');
    const formattedPlace = placeNames[place].toUpperCase();
    const formattedPhoneNumber = phoneNumber.replace(/\s+|-+/g, '');

    try {
      const response = await postRequest<CalendeskValidationResponse>(
        'validateCalendesk',
        {
          date: formattedDate,
          time,
          place: formattedPlace,
          email,
          phoneNumber: formattedPhoneNumber
        }
      );
      return response.data;
    } catch {
      return {
        success: false,
        error: true
      };
    }
  };

  return { validateCalendeskBooking };
};

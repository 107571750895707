import { useCallback, useState } from 'react';

import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useShallow } from 'zustand/react/shallow';

import { useRequestsHandler } from 'src/hooks';
import {
  FormPreview,
  TableEndpoint,
  Filters
} from 'src/modules/Admin/AdminTableWithFilters';

import { useAuth } from './useAuth';

const getPreviewData = (data: FormPreview[]): FormPreview[] =>
  data.map(form => ({
    ...form,
    read: form.read,
    date: new Date(form.date),
    displayDate: format(new Date(form.date), 'dd/MM/yyyy')
  }));

interface FormsResponse {
  forms: FormPreview[];
  totalRecords: number;
}
export const useFetchForms = (
  endpoint: TableEndpoint,
  filters: Filters,
  page: number,
  rowsPerPage: number
) => {
  const [formsPreview, setFormsPreview] = useState<FormPreview[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { getRequest } = useRequestsHandler();
  const { token, clearToken } = useAuth(
    useShallow(state => ({
      token: state.token,
      clearToken: state.clearToken
    }))
  );

  const fetchForms = useCallback(
    async (noFilter = false) => {
      setIsLoading(true);
      await getRequest<FormsResponse>(
        endpoint,
        undefined,
        noFilter
          ? {}
          : {
              email: filters.email ?? null,
              phone_number: filters.phoneNumber ?? null,
              place: filters.place ?? null,
              time: filters.time || null,
              start_date:
                filters.startDate instanceof Date
                  ? filters.startDate.toISOString()
                  : null,
              end_date:
                filters.endDate instanceof Date
                  ? filters.endDate.toISOString()
                  : null,
              page_number: page,
              page_size: rowsPerPage
            }
      )
        .then(({ data }) => {
          setFormsPreview(getPreviewData(data.forms));
          setTotalRecords(data.totalRecords);
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 401) {
            clearToken();
          }

          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      clearToken,
      endpoint,
      filters.email,
      filters.endDate,
      filters.phoneNumber,
      filters.place,
      filters.startDate,
      filters.time,
      getRequest,
      page,
      rowsPerPage
    ]
  );

  return {
    token,
    isLoading,
    isError,
    formsPreview,
    fetchForms,
    totalRecords
  };
};

import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  typography: {
    fontFamily: ['Roboto', 'serif'].join(),

    h2: {
      fontFamily: ['Oswald', 'Roboto', 'serif'].join()
    }
  }
});

theme = responsiveFontSizes(theme);

export { theme };

import React from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { AppRouter } from 'src/components/AppRouter';

import { theme } from './styles';

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      >
        <AppRouter />
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  );
};

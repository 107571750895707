import * as yup from 'yup';

type InvoiceFormData = {
  firstname: string;
  lastname: string;
  company: string;
  street: string;
  houseNo: string;
  aptNo?: string;
  zipCode: string;
  city: string;
  nip?: string;
};

const invoiceSchema = yup.object<Record<keyof InvoiceFormData, yup.AnySchema>>({
  firstname: yup.string().required('Imię jest wymagane'),
  lastname: yup.string().required('Nazwisko jest wymagane'),
  company: yup.string(),
  street: yup.string().required('Ulica jest wymagana'),
  houseNo: yup.string().required('Numer domu jest wymagany'),
  aptNo: yup.string(),
  zipCode: yup
    .string()
    .required('Kod pocztowy jest wymagany')
    .matches(/^\d{2}-\d{3}$|^\d{5}$/, 'Kod pocztowy jest niepoprawny'),
  city: yup.string().required('Miasto jest wymagana'),
  nip: yup.string().length(10, 'NIP jest niepoprawny')
});

const invoiceFormInitialValues: InvoiceFormData = {
  firstname: '',
  lastname: '',
  company: '',
  street: '',
  houseNo: '',
  aptNo: '',
  zipCode: '',
  city: '',
  nip: ''
};

export type { InvoiceFormData };
export { invoiceSchema, invoiceFormInitialValues };

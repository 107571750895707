import { useCallback, useState } from 'react';

import { useRequestsHandler } from './useRequestsHandler';

export const useReCaptcha = () => {
  const [token, setToken] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { postRequest } = useRequestsHandler();

  const handleReCaptchaToken = useCallback((newToken: string) => {
    setToken(newToken);
  }, []);

  const handleRefreshReCaptcha = useCallback(() => {
    setRefreshReCaptcha(prevState => !prevState);
  }, []);

  const validateReCaptcha = useCallback(async (): Promise<boolean> => {
    try {
      const {
        data: { success, score }
      } = await postRequest<{ success: boolean; score: number }>(
        'validateRecaptcha',
        {
          g_recaptcha_response: token
        }
      );

      return success && score >= 0.5;
    } catch {
      return false;
    }
  }, [postRequest, token]);

  return {
    handleReCaptchaToken,
    handleRefreshReCaptcha,
    validateReCaptcha,
    refreshReCaptcha
  };
};

import { Backdrop, CircularProgress } from '@mui/material';

export const FullScreenLoader = () => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: theme => theme.zIndex.drawer + 1,
        backgroundColor: 'transparent'
      }}
      open
    >
      <CircularProgress
        sx={{ color: 'rgb(70, 80, 194)' }}
        thickness={5.5}
        size={30}
      />
    </Backdrop>
  );
};

import type { ReactNode } from 'react';

import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';

interface FlexBoxProps {
  children: ReactNode;
  dir?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  mt?: number;
  mb?: number;
  m?: number | string;
  pt?: number | string;
  pb?: number | string;
  width?: string | number | number[];
  maxWidth?: number;
  sx?: SxProps<Theme>;
}

export const FlexBox = ({
  children,
  dir = 'column',
  justify = 'center',
  align = 'center',
  m,
  mt,
  mb,
  pt,
  pb,
  width,
  maxWidth,
  sx = {}
}: FlexBoxProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: dir,
      justifyContent: justify,
      alignItems: align,
      m,
      mt,
      mb,
      width,
      maxWidth,
      pb,
      pt,
      ...sx
    }}
  >
    {children}
  </Box>
);

import { add } from 'date-fns';
import * as yup from 'yup';

export type Place = 'blue' | 'pink' | 'green' | '';

type PersonalFormData = {
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  date: Date | null;
  time: string;
  place: Place;
  lastMinute: boolean;
};

export type CustomPersonalFormData = Omit<PersonalFormData, 'date'> & {
  date: string;
};

const personalSchemaBase = {
  firstname: yup.string().required('Imię jest wymagane'),
  lastname: yup.string().required('Nazwisko jest wymagane'),
  email: yup
    .string()
    .email('Wpisz poprawy adres email')
    .required('Email jest wymagany'),
  phoneNumber: yup
    .string()
    .min(15, 'Numer jest nieprawidłowy')
    .required('Numer telefonu jest wymagany'),
  date: yup
    .date()
    .min(
      add(new Date(), { days: 3 }),
      'Data nie może być wcześniejsza niż 3 dni od dzisiaj'
    )
    .max(
      new Date(add(new Date(), { years: 1 })),
      'Data nie może być późniejsza niż na rok od dnia dzisiejszego'
    )
    .required('Data jest wymagana')
    .nullable(),
  time: yup.string().required('Godzina jest wymagana'),
  place: yup.string().required('Wybierz salkę'),
  lastMinute: yup.boolean()
};

const personalSchema =
  yup.object<Record<keyof PersonalFormData, yup.AnySchema>>(personalSchemaBase);

export const personalSchemaEdit = yup.object<
  Record<keyof PersonalFormData, yup.AnySchema>
>({
  ...personalSchemaBase,
  date: yup
    .date()
    .min(new Date(), 'Data nie może być wcześniejsza niż dzień dzisiejszy.')
    .max(
      new Date(add(new Date(), { years: 1 })),
      'Data nie może być późniejsza niż na rok od dnia dzisiejszego'
    )
    .required('Data jest wymagana')
    .nullable()
});

const personalFormInitialValues: PersonalFormData = {
  firstname: '',
  lastname: '',
  email: '',
  phoneNumber: '',
  date: null,
  time: '',
  place: '',
  lastMinute: false
};

const placeHours: Record<Exclude<Place, ''>, string[]> = {
  blue: ['14:00', '16:00', '17:00'],
  pink: ['13:45', '15:45', '16:45'],
  green: ['14:15', '16:15', '17:15']
};

const placeHoursWeekend: Record<Exclude<Place, ''>, string[]> = {
  blue: ['12:00', '14:00', '16:00', '17:00'],
  pink: ['12:10', '13:45', '15:45', '16:45'],
  green: ['12:15', '14:15', '16:15', '17:15']
};

// birthday time, place start, place end, food

const timeFrames = {
  '12:00': ['13:45', '14:45', '13:00'],
  '12:10': ['13:55', '14:55', '13:00'],
  '12:15': ['14:00', '15:00', '13:00'],
  '13:45': ['15:30', '16:30', '15:00'],
  '14:00': ['15:45', '16:45', '15:00'],
  '14:15': ['16:00', '17:00', '15:00'],
  '15:45': ['17:30', '18:30', '17:00'],
  '16:00': ['17:45', '18:45', '17:00'],
  '16:15': ['18:00', '19:00', '17:00'],
  '16:45': ['17:45', '19:30', '19:00'],
  '17:00': ['18:00', '19:45', '19:00'],
  '17:15': ['18:15', '20:00', '19:00']
};

export {
  personalSchema,
  personalFormInitialValues,
  placeHours,
  placeHoursWeekend,
  timeFrames
};
export type { PersonalFormData };

import { useState, useCallback, useMemo, MouseEvent } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link, useHistory } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { FlexBox } from 'src/components/FlexBox';
import { routes } from 'src/helpers';
import { useAuth } from 'src/hooks';
import logo from 'src/images/logo.png';

import { styles } from './styles';

interface NavbarItemProps {
  onClick?: () => void;
  route: string;
  label: string;
  newTab: boolean;
}

const NavbarMenuItem = ({ onClick, route, label, newTab }: NavbarItemProps) =>
  route ? (
    <MenuItem onClick={onClick}>
      <Link
        to={{ pathname: route, state: { prevPath: location.pathname } }}
        target={newTab ? '_blank' : '_self'}
        rel={newTab ? 'noopener noreferrer' : ''}
      >
        <Typography component="div" sx={styles.menuItemText}>
          {label}
        </Typography>
      </Link>
    </MenuItem>
  ) : (
    <MenuItem onClick={onClick}>
      <Typography component="div" sx={styles.menuItemText}>
        {label}
      </Typography>
    </MenuItem>
  );

const NavbarLinkItem = ({ onClick, route, label, newTab }: NavbarItemProps) =>
  route ? (
    <Box sx={styles.menuLinkItem}>
      <Link
        to={{ pathname: route, state: { prevPath: location.pathname } }}
        target={newTab ? '_blank' : '_self'}
        rel={newTab ? 'noopener noreferrer' : ''}
        style={styles.menuLink}
      >
        <Typography>{label}</Typography>
      </Link>
    </Box>
  ) : (
    <Box sx={styles.menuLinkItem}>
      <Typography component="div" sx={styles.menuLink} onClick={onClick}>
        {label}
      </Typography>
    </Box>
  );

export const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [superAnchorEl, setSuperAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();
  const { token, superAdmin, clearToken } = useAuth(
    useShallow(state => ({
      token: state.token,
      superAdmin: state.superAdmin,
      clearToken: state.clearToken
    }))
  );

  const open = Boolean(anchorEl);
  const superOpen = Boolean(superAnchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSuperClick = (event: MouseEvent<HTMLButtonElement>) => {
    setSuperAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSuperClose = useCallback(() => {
    setSuperAnchorEl(null);
  }, []);

  const handleLogout = useCallback(() => {
    clearToken();
    handleClose();
    history.push('/');
  }, [clearToken, handleClose, history]);

  const menuLinks = [
    {
      label: 'Kalendarz',
      route: routes.calendar,
      newTab: false
    },
    {
      label: 'Formularz',
      route: routes.form,
      newTab: false
    },
    {
      label: 'Regulamin',
      route: routes.terms,
      newTab: true
    },
    {
      label: 'Polityka Prywatności',
      route: routes.privacy,
      newTab: true
    },
    {
      label: 'Kontakt',
      route: routes.contact,
      newTab: false
    }
  ];
  const adminLinks = useMemo(() => {
    if (!token) {
      return [];
    }
    return [
      {
        label: 'Formularze',
        route: routes.forms,
        newTab: false,
        onClick: handleClose
      },
      {
        label: 'Wyloguj',
        route: '',
        newTab: false,
        onClick: handleLogout
      }
    ];
  }, [handleClose, handleLogout, token]);

  const superAdminLinks = useMemo(() => {
    if (!(token && superAdmin)) {
      return [];
    }

    return [
      {
        label: 'Przedawnione',
        route: routes.expired,
        newTab: false,
        onClick: handleSuperClose
      },
      {
        label: 'Usunięte',
        route: routes.deleted,
        newTab: false,
        onClick: handleSuperClose
      },
      {
        label: 'Cennik',
        route: routes.prices,
        newTab: false,
        onClick: handleSuperClose
      }
    ];
  }, [handleSuperClose, superAdmin, token]);

  return (
    <Box sx={styles.container}>
      <AppBar position="static">
        <Toolbar sx={styles.toolbar}>
          <Box sx={styles.logoContainer}>
            <Link
              to={{
                pathname: routes.home,
                state: { prevPath: location.pathname }
              }}
              style={styles.logoLink}
            >
              <img src={logo} alt="logo" style={styles.logo} />

              <Typography component="div" sx={styles.heading}>
                WMB - Formularz urodzinowy
              </Typography>
            </Link>
          </Box>
          <FlexBox dir="row" justify="flex-start">
            <IconButton
              id="menu-button"
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              aria-controls={open ? 'menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={styles.menuIcon}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              sx={styles.menu}
            >
              {menuLinks.map(({ label, route, newTab }) => (
                <NavbarMenuItem
                  key={label}
                  onClick={handleClose}
                  route={route}
                  label={label}
                  newTab={newTab}
                />
              ))}
              {adminLinks.map(({ label, route, newTab, onClick }) => (
                <NavbarMenuItem
                  key={label}
                  onClick={onClick}
                  route={route}
                  label={label}
                  newTab={newTab}
                />
              ))}
            </Menu>

            {menuLinks.map(({ label, route, newTab }) => (
              <NavbarLinkItem
                key={label}
                route={route}
                label={label}
                newTab={newTab}
              />
            ))}
            {token && (
              <>
                <NavbarLinkItem
                  key={adminLinks[0].label}
                  route={adminLinks[0].route}
                  label={adminLinks[0].label}
                  newTab={adminLinks[0].newTab}
                />

                {superAdmin && (
                  <>
                    <Button
                      variant="outlined"
                      id="super-button"
                      aria-controls={superOpen ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={superOpen ? 'true' : undefined}
                      onClick={handleSuperClick}
                      sx={styles.superAdminMenu}
                    >
                      <Typography component="div" sx={styles.moreText}>
                        Więcej
                      </Typography>
                    </Button>
                    <Menu
                      id="super-menu"
                      anchorEl={superAnchorEl}
                      open={superOpen}
                      onClose={handleSuperClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button'
                      }}
                    >
                      {superAdminLinks.map(
                        ({ label, route, newTab, onClick }) => (
                          <NavbarMenuItem
                            key={label}
                            onClick={onClick}
                            route={route}
                            label={label}
                            newTab={newTab}
                          />
                        )
                      )}
                    </Menu>
                  </>
                )}

                <NavbarLinkItem
                  key={adminLinks[1].label}
                  route={adminLinks[1].route}
                  label={adminLinks[1].label}
                  newTab={adminLinks[1].newTab}
                  onClick={adminLinks[1].onClick}
                />
              </>
            )}
          </FlexBox>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

import React, { lazy, Suspense } from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { Layout } from 'src/components/Layout';
import { NotFound } from 'src/components/NotFound';
import { routes } from 'src/helpers';
import { useAuth } from 'src/hooks';

import { FullScreenLoader } from '../FullScreenLoader';

const Home = lazy(() =>
  import('src/views').then(view => ({
    default: view.Home
  }))
);
const Terms = lazy(() =>
  import('src/views').then(view => ({
    default: view.Terms
  }))
);
const PrivacyPolicy = lazy(() =>
  import('src/views').then(view => ({
    default: view.PrivacyPolicy
  }))
);
const AdminLogin = lazy(() =>
  import('src/views').then(view => ({
    default: view.AdminLogin
  }))
);
const AdminList = lazy(() =>
  import('src/views').then(view => ({
    default: view.AdminList
  }))
);
const AdminForm = lazy(() =>
  import('src/views').then(view => ({
    default: view.AdminForm
  }))
);
const AdminDeleted = lazy(() =>
  import('src/views').then(view => ({
    default: view.AdminDeleted
  }))
);
const AdminExpired = lazy(() =>
  import('src/views').then(view => ({
    default: view.AdminExpired
  }))
);
const AdminEdit = lazy(() =>
  import('src/views').then(view => ({
    default: view.AdminEdit
  }))
);
const AdminPrices = lazy(() =>
  import('src/views').then(view => ({
    default: view.AdminPrices
  }))
);
const Form = lazy(() =>
  import('src/views').then(view => ({
    default: view.Form
  }))
);
const Calendar = lazy(() =>
  import('src/views').then(view => ({
    default: view.Calendar
  }))
);
const Contact = lazy(() =>
  import('src/views').then(view => ({
    default: view.Contact
  }))
);

export const AppRouter = () => {
  const { token, superAdmin } = useAuth(
    useShallow(state => ({
      token: state.token,
      superAdmin: state.superAdmin
    }))
  );

  return (
    <Router>
      <ErrorBoundary>
        <Layout>
          <Switch>
            <Suspense fallback={<FullScreenLoader />}>
              <Route path={routes.home} exact>
                <Home />
              </Route>{' '}
              <Route path={routes.form} exact>
                <Form />
              </Route>
              <Route path={routes.calendar} exact>
                <Calendar />
              </Route>
              <Route path={routes.terms} exact>
                <Terms />
              </Route>
              <Route path={routes.privacy} exact>
                <PrivacyPolicy />
              </Route>{' '}
              <Route path={routes.contact} exact>
                <Contact />
              </Route>
              <Route path={routes.admin} exact>
                <AdminLogin />
              </Route>
              {token && (
                <Route path={routes.forms} exact>
                  <AdminList />
                </Route>
              )}
              {token && (
                <Route path={routes.formId} exact>
                  <AdminForm />
                </Route>
              )}
              {token && (
                <Route path={routes.edit} exact>
                  <AdminEdit />
                </Route>
              )}
              {token && superAdmin && (
                <Route path={routes.expired} exact>
                  <AdminExpired />
                </Route>
              )}
              {token && superAdmin && (
                <Route path={routes.deleted} exact>
                  <AdminDeleted />
                </Route>
              )}
              {token && superAdmin && (
                <Route path={routes.prices} exact>
                  <AdminPrices />
                </Route>
              )}
            </Suspense>
            <Route path={routes.rest}>
              <NotFound />
            </Route>
          </Switch>
        </Layout>
      </ErrorBoundary>
    </Router>
  );
};

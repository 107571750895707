import { ReactNode } from 'react';

import * as Sentry from '@sentry/react';

import { NotFound } from 'src/components/NotFound';

type ErrorBoundaryProps = {
  children: ReactNode;
};

const renderFallback = () => <NotFound error />;

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <Sentry.ErrorBoundary fallback={renderFallback}>
    {children}
  </Sentry.ErrorBoundary>
);

import * as yup from 'yup';

import { Prices } from 'src/hooks';

const requiredMsg = 'To pole jest wymagane';

export const pricesSchema = yup.object<Record<keyof Prices, yup.AnySchema>>({
  anotherBirthdayPersonPrice: yup.number().min(1).required(requiredMsg),
  participantPrice: yup.number().min(1).required(requiredMsg),
  participantWeekendPrice: yup.number().min(1).required(requiredMsg),
  tablePrice: yup.number().min(1).required(requiredMsg),
  animatorPrice: yup.number().min(1).required(requiredMsg),
  supportPrice: yup.number().min(1).required(requiredMsg),
  themePrice: yup.number().min(1).required(requiredMsg),
  themeOverPrice: yup.number().min(1).required(requiredMsg),
  smallCakePrice: yup.number().min(1).required(requiredMsg),
  mediumCakePrice: yup.number().min(1).required(requiredMsg),
  largeCakePrice: yup.number().min(1).required(requiredMsg),
  wafferPrice: yup.number().min(1).required(requiredMsg),
  cakeFountainPrice: yup.number().min(1).required(requiredMsg),
  ownCakePrice: yup.number().min(1).required(requiredMsg),
  kidChampagnePrice: yup.number().min(1).required(requiredMsg),
  pizzaMargheritaPrice: yup.number().min(1).required(requiredMsg),
  pizzaMargheritaHamPrice: yup.number().min(1).required(requiredMsg),
  friesPrice: yup.number().min(1).required(requiredMsg),
  nuggetsPrice: yup.number().min(1).required(requiredMsg),
  fruitsPrice: yup.number().min(1).required(requiredMsg),
  iceCreamPrice: yup.number().min(1).required(requiredMsg),
  balloonPrice: yup.number().min(1).required(requiredMsg),
  balloonWithNumberPrice: yup.number().min(1).required(requiredMsg),
  piniataPrice: yup.number().min(1).required(requiredMsg),
  piniataOverPrice: yup.number().min(1).required(requiredMsg)
});

export const pricesFields: { id: keyof Prices; label: string }[] = [
  {
    id: 'participantPrice',
    label: 'Uczestnik urodzin'
  },
  {
    id: 'participantWeekendPrice',
    label: 'Uczestnik urodzin (sob, nd, święta)'
  },
  {
    id: 'anotherBirthdayPersonPrice',
    label: 'Dodatkowy solenizant'
  },
  {
    id: 'tablePrice',
    label: 'Stolik >8 osób'
  },
  {
    id: 'animatorPrice',
    label: 'Animator'
  },
  {
    id: 'supportPrice',
    label: 'Animator - 2 osoby'
  },
  {
    id: 'themePrice',
    label: 'Motyw urodzin'
  },
  {
    id: 'themeOverPrice',
    label: 'Motyw >10 osób za osobę'
  },
  {
    id: 'smallCakePrice',
    label: 'Mały tort'
  },
  {
    id: 'mediumCakePrice',
    label: 'Średni tort'
  },
  {
    id: 'largeCakePrice',
    label: 'Duży tort'
  },
  {
    id: 'wafferPrice',
    label: 'Opłatek na torcie'
  },
  {
    id: 'cakeFountainPrice',
    label: 'Fontanna tortowa'
  },
  {
    id: 'ownCakePrice',
    label: 'Własny tort lub piniata'
  },
  {
    id: 'kidChampagnePrice',
    label: 'Szampan dla dzieci'
  },
  {
    id: 'pizzaMargheritaPrice',
    label: 'Pizza Margarita 32 cm'
  },
  {
    id: 'pizzaMargheritaHamPrice',
    label: 'Pizza z szynką 32 cm'
  },
  {
    id: 'friesPrice',
    label: 'Frytki + sos (100g)'
  },
  {
    id: 'nuggetsPrice',
    label: 'Nuggets (5 szt.)'
  },
  {
    id: 'fruitsPrice',
    label: 'Talerz owoców'
  },
  {
    id: 'iceCreamPrice',
    label: 'Lód w wafelku (1 gałka)'
  },
  {
    id: 'balloonPrice',
    label: 'Balon foliowy 18” wypełniony helem'
  },
  {
    id: 'balloonWithNumberPrice',
    label: 'Balon Cyfra wypełniony helem'
  },
  {
    id: 'piniataPrice',
    label: 'Zaczarowana Piniata'
  },
  {
    id: 'piniataOverPrice',
    label: 'Piniata >8 osób za osobę'
  }
];

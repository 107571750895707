import { KeyboardEvent, useState } from 'react';

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';

import { handleTokenRequest } from 'src/hooks';

import { useReCaptcha } from './useReCaptcha';

type UseAuth = {
  token?: string;
  superAdmin: boolean;
  setToken: (token: string, username: string) => void;
  clearToken: () => void;
};

export const useAuth = create(
  persist<UseAuth>(
    set => ({
      token: undefined,
      superAdmin: false,
      setToken: (token: string, username) =>
        set({ token, superAdmin: username.includes('super') }),
      clearToken: () => set({ token: undefined, superAdmin: false })
    }),
    {
      name: 'auth-token'
    }
  )
);

export const useAuthLogin = (username: string, password: string) => {
  const [error, setError] = useState('');

  const { token, setToken } = useAuth(
    useShallow(state => ({
      token: state.token,
      setToken: state.setToken
    }))
  );

  const {
    handleReCaptchaToken,
    handleRefreshReCaptcha,
    validateReCaptcha,
    refreshReCaptcha
  } = useReCaptcha();

  const handleClearError = () => setError('');

  const handleSubmit = async () => {
    if (!username || !password) {
      setError('Brak podanego loginu lub hasła');
      return;
    }

    if (process.env.REACT_APP_ENV === 'prod') {
      try {
        const isReCaptchaValid = await validateReCaptcha();

        if (!isReCaptchaValid) {
          throw new Error('ReCaptcha validation failed!');
        }
      } catch {
        setError('Brak weryfikacji reCaptcha');
        return;
      }
    }

    await handleTokenRequest(username, password)
      .then(response => {
        setToken(response.data.access_token, username);
      })
      .catch(() => {
        setError('Zły login lub hasło');
      })
      .finally(() => {
        handleRefreshReCaptcha();
      });
  };

  const handleKeyPress = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await handleSubmit();
    }
  };

  return {
    token,
    handleSubmit,
    handleKeyPress,
    error,
    handleClearError,
    handleReCaptchaToken,
    refreshReCaptcha
  };
};

import React from 'react';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom';

import './styles/index.css';
import { App } from './App';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1,
  environment: process.env.NODE_ENV
});

ReactDOM.render(
  <React.StrictMode>
    <>
      <App />
    </>
  </React.StrictMode>,
  document.getElementById('root')
);
